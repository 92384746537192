(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/datatable"), require("primevue/inlinemessage"), require("primevue/column"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/fileupload"), require("vue-router"), require("primevue/toastservice"), require("primevue/autocomplete"), require("primevue/message"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-repo"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/tabview"), require("primevue/dialog"), require("primevue/button"), require("primevue/tabpanel"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/calendar", "primevue/textarea", "primevue/inputswitch", "primevue/datatable", "primevue/inlinemessage", "primevue/column", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "primevue/fileupload", "vue-router", "primevue/toastservice", "primevue/autocomplete", "primevue/message", "vue", "primevue/inputnumber", "@netvision/lib-api-repo", "primevue/toast", "primevue/progressbar", "primevue/tabview", "primevue/dialog", "primevue/button", "primevue/tabpanel"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/datatable"), require("primevue/inlinemessage"), require("primevue/column"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("primevue/fileupload"), require("vue-router"), require("primevue/toastservice"), require("primevue/autocomplete"), require("primevue/message"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-repo"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/tabview"), require("primevue/dialog"), require("primevue/button"), require("primevue/tabpanel")) : factory(root["primevue/inputtext"], root["primevue/calendar"], root["primevue/textarea"], root["primevue/inputswitch"], root["primevue/datatable"], root["primevue/inlinemessage"], root["primevue/column"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["primevue/fileupload"], root["vue-router"], root["primevue/toastservice"], root["primevue/autocomplete"], root["primevue/message"], root["vue"], root["primevue/inputnumber"], root["@netvision/lib-api-repo"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/tabview"], root["primevue/dialog"], root["primevue/button"], root["primevue/tabpanel"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__0482__, __WEBPACK_EXTERNAL_MODULE__12bc__, __WEBPACK_EXTERNAL_MODULE__199d__, __WEBPACK_EXTERNAL_MODULE__20c0__, __WEBPACK_EXTERNAL_MODULE__21f8__, __WEBPACK_EXTERNAL_MODULE__3724__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__5b27__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__6920__, __WEBPACK_EXTERNAL_MODULE__6e08__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__938d__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dbbc__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_f095__, __WEBPACK_EXTERNAL_MODULE_f364__) {
return 