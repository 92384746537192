import InputField from "./fields/InputField.vue";
import JSONField from "./fields/JSONField.vue";
import ArrayField from "./fields/ArrayField.vue";
import NumberField from "./fields/NumberField.vue";
import ToggleField from "./fields/ToggleField.vue";
import EnumField from "./fields/EnumField.vue";
import TextField from "./fields/TextField.vue";
import ReferenceField from "./fields/ReferenceField.vue";
import DatePickerField from "./fields/DatePickerField.vue";
import FileUploadField from "./fields/FileUploadField.vue";
import LicenseForm from "./fields/LicenseForm.vue";
import ActivationMetadata from "./fields/ActivationMetadata.vue"
import MetadataField from './fields/MetadataField.vue'

const arrayMap = new Map();
arrayMap.set('string', InputField);
arrayMap.set('enum', EnumField);
arrayMap.set('number', NumberField);

const componentMap = new Map()
componentMap.set('string', InputField);
componentMap.set('text', TextField);
componentMap.set('object', JSONField);
componentMap.set('number', NumberField);
componentMap.set('array', ArrayField);
componentMap.set('boolean', ToggleField);
componentMap.set('enum', EnumField);
componentMap.set('reference', ReferenceField);
componentMap.set('date', DatePickerField);
componentMap.set('file', FileUploadField);
componentMap.set('licenseForm', LicenseForm);
componentMap.set('licenseActivation', LicenseForm);
componentMap.set('activationMetadata', ActivationMetadata)
componentMap.set('metadata', MetadataField)

export {
  arrayMap,
  componentMap
}
