var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FieldLabel',{attrs:{"options":_vm.options},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('Button',{staticClass:"p-button-outlined p-button-secondary p-button-sm",attrs:{"icon":"mdi mdi-20px mdi-plus"},on:{"click":_vm.addElement}})]},proxy:true}])},[_c('DataTable',{attrs:{"value":_vm.localValue,"editMode":"cell"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"p-d-flex p-jc-center",staticStyle:{"width":"100%"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('empty'))}})])]},proxy:true}])},[_vm._l((_vm.options.fields),function(field){return _c('Column',{key:field.title,attrs:{"field":field.title,"header":_vm.te(`${_vm.entityType}.attrs.${field.title}`, field.title)},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"p-d-flex p-ai-center",staticStyle:{"height":"42px"}},[(field.type === 'enum')?_c('span',{staticStyle:{"pointer-events":"none"},domProps:{"textContent":_vm._s(
              slotProps.data[field.title] !== undefined
                ? _vm.te(
                    `${_vm.entityType}.enumOptions.${
                      slotProps.data[field.title]
                    }`,
                    slotProps.data[field.title]
                  )
                : _vm.$t('empty')
            )}}):_c('span',{staticStyle:{"pointer-events":"none"},domProps:{"textContent":_vm._s(
              slotProps.data[field.title] !== undefined
                ? slotProps.data[field.title]
                : _vm.$t('empty')
            )}})])]}},{key:"editor",fn:function(slotProps){return [_c('div',{staticClass:"table-row"},[_c(_vm.componentMap.get(field.type) || _vm.InputField,{tag:"component",staticStyle:{"padding":"0","width":"100%"},attrs:{"entityType":_vm.entityType,"entity":_vm.localValue[slotProps.index],"options":field,"context":_vm.context},on:{"commit":(event) => _vm.commit(slotProps, event, field.title)}})],1)]}}],null,true)})}),_c('Column',{attrs:{"headerStyle":"width: 2em"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"icon":"mdi mdi-20px mdi-close"},on:{"click":function($event){return _vm.deleteElement(slotProps)}}})]}}])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }