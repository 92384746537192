var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"auto-complete p-fluid"},[_c('AutoComplete',{staticStyle:{"width":"100%"},attrs:{"field":_vm.searchField,"suggestions":_vm.suggestions,"placeholder":_vm.label,"minLength":2,"delay":500,"disabled":!_vm.editable,"dropdown":""},on:{"complete":() => _vm.localValue.length && _vm.fetchEntities(),"dropdown-click":_vm.fetchEntities,"item-select":(e) => {
        _vm.localValue = '';
        _vm.$emit('onSelect', e);
      },"item-unselect":(e) => {
        _vm.localValue = '';
        _vm.$emit('unselect', e);
      }},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('div',[_c('span',{domProps:{"textContent":_vm._s(
            slotProps.item[_vm.searchField]
              ? slotProps.item[_vm.searchField]
              : _vm.$t('noLabel')
          )}})])]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }