
import Vue from "vue";
import InputField from "./fields/InputField.vue";
import JSONField from "./fields/JSONField.vue";
import FieldsList from "./FieldsList.vue";
import ErrorMessages from "./common/ErrorMessages.vue";
import { createEditObject } from "../utils";
import { required } from "vuelidate/lib/validators";
import { intersection, set } from "lodash";
import { mapState } from "vuex";

const STRINGS_REGEX = /[&?/#<>"'=;()]/;
const DURATION_REGEX =
  /^((\d+y\s*)?(\s*)?(\d+M\s*)?(\s*)?(\d+w\s*)?(\s*)?(\d+d\s*)?(\s*)?(\d+h\s*)?(\s*)?(\d+m\s*)?(\s*)?(\d+s\s*)?)$/;

export default Vue.extend({
  name: "AdminDialog",
  props: {
    entity: Object as () => AdminDialogEntity,
    loading: Boolean,
    entityType: String,
    withPermissions: Boolean,
    entityTypeMetadata: Object as () => EntityMetadata,
  },
  components: {
    JSONField,
    ErrorMessages,
    FieldsList,
  },
  data() {
    return {
      visible: true,
      InputField,
      displayDeleteDialog: false,
      changeMap: new Map() as Map<string, string>,
      localEntity: {
        [`${this.entityType}${this._uid}`]: JSON.parse(
          JSON.stringify(this.entity)
        ),
      },
      isValidJSON: true,
    };
  },
  schema: [
    {
      mountPoint: "localEntity",
      schema: {},
    },
  ],
  validations(): any {
    const validators = {} as Record<string, any>;
    if (this.entityTypeMetadata.viewDialog?.tabs) {
      const fields = this.entityTypeMetadata.viewDialog.tabs.reduce(
        (acc: any[], { fields }: any) => {
          return [...acc, ...fields];
        },
        []
      );
      fields.forEach((option: any) => {
        if (["string", "text"].includes(option.type)) {
          set(validators, option.title, {
            schemaPattern: (val: string) => !val || !STRINGS_REGEX.test(val),
          });
        }
        if (option.type === "duration") {
          set(validators, option.title, {
            durationPattern: (val: string) => {
              return (
                typeof val === "number" || !val || DURATION_REGEX.test(val)
              );
            },
          });
        }
        if (["multiReference", "reference"].includes(option.type)) {
          set(validators, option.title, {
            ...validators[option.title],
            isReferenceValid: (val: any) => {
              switch (true) {
                case typeof val === "string":
                  return this.existingReferenceIds.includes(val);
                case Array.isArray(val):
                  return (
                    intersection(this.existingReferenceIds, val).length ===
                    val.length
                  );
                default:
                  return true;
              }
            },
          });
        }
      });
    }
    const requiredFields: string[] =
      this.entityTypeMetadata.schemaCreate &&
      this.entityTypeMetadata.schemaCreate.required;
    if (requiredFields) {
      requiredFields.forEach((key) => {
        set(validators, key, { ...validators[key], required });
      });
    }
    return { localEntity: { [`${this.entityType}${this._uid}`]: validators } };
  },
  provide(): any {
    return { $v: this.$v, uid: this._uid };
  },
  computed: {
    ...mapState(["existingReferenceIds", "api"]),
    attributes(): any {
      const attributes = {} as Record<string, string>;
      if (this.entityTypeMetadata.localeRu?.attrs) {
        Object.entries(
          this.entityTypeMetadata.localeRu.attrs as Record<string, string>
        ).forEach(([key, value]) => {
          attributes[key] = value;
        });
      }
      return attributes;
    },
    tabs(): ViewDialogTab[] {
      return this.entityTypeMetadata.viewDialog.tabs.filter(
        ({ forbidenOnCreate }) => this.entity.id || !forbidenOnCreate
      );
    },
  },
  methods: {
    isTabDisabled(param?: string) {
      if (!param) return false;
      const tabDependencyParam =
        this.localEntity[`${this.entityType}${this._uid}`][param];
      return !tabDependencyParam || !tabDependencyParam.length;
    },
    commit(entity: AdminDialogEntity) {
      this.localEntity = { [`${this.entityType}${this._uid}`]: { ...entity } };
    },
    deleteEntity() {
      this.api
        .deleteEntity({
          type: this.entityType,
          id: this.entity.id,
        })
        .then(() => this.$emit("onClose", true))
        .catch((error: Error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("error"),
            detail: error.message,
            life: 5000,
          });
        });
    },
    saveEntity(
      changedEntity: AdminDialogEntity,
      originalEntity: AdminDialogEntity,
      closeCallback: () => void
    ) {
      createEditObject(
        { ...changedEntity, type: this.entityType },
        { ...originalEntity, type: this.entityType },
        closeCallback
      )
        .then((entity) => {
          if (entity) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.success"),
              detail: this.$t("message.successfulCreated"),
              life: 5000,
            });
            this.$emit("updateEntity", entity);
            this.localEntity = {
              [`${this.entityType}${this._uid}`]: entity,
            };
          }
        })
        .catch((error: Error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("error"),
            detail: error.message,
            life: 5000,
          });
        });
    },
  },
  async mounted() {
    const schema = {} as any;
    const { schemaCreate } = this.entityTypeMetadata;
    if (schemaCreate) {
      schema.mountPoint = `localEntity.${this.entityType}${this._uid}`;
      for (const [key, property] of Object.entries(schemaCreate.properties) as [
        string,
        any
      ]) {
        if (property.$ref) {
          try {
            const {
              entity: {
                schemaCreate: schemaCreateInternal,
                localeRu: refLocales,
              },
            } = await this.api.getEntity({
              id: property.$ref,
              type: "EntityTypeMetadata",
            });
            if (refLocales) {
              this.$i18n.vm.messages[this.$i18n.locale][this.entityType].attrs =
                {
                  ...this.$i18n.messages[this.$i18n.locale][this.entityType][
                    "attrs"
                  ],
                  ...refLocales.attrs,
                };
            }
            schemaCreate.properties[key] = schemaCreateInternal || {};
          } catch (error) {
            console.error(error);
          }
        }
      }
      delete schemaCreate.additionalProperties;
      delete schemaCreate.type;
      schema.schema = schemaCreate;
    }
    this.$schema.push(schema);
  },
});
