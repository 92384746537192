
import Vue from "vue";
import { mapState } from "vuex";
import AutoComplete from "primevue/autocomplete";
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g;

export default Vue.extend({
  name: "AutocompleteField",
  components: {
    AutoComplete,
  },
  props: {
    value: String,
    editable: Boolean,
    entityType: String,
    searchField: String,
    dependsFieldsParams: Object,
    label: String,
  },
  data() {
    return {
      localValue: this.value,
      suggestions: [] as any[],
    };
  },
  computed: {
    ...mapState(["strictRegEx", "api"]),
  },
  watch: {
    localValue(val: string) {
      const clearedVal = val.replace(strictRegEx, "");
      if (this.localValue.length !== clearedVal.length) {
        this.localValue = clearedVal;
      }
    },
    value(val) {
      this.localValue = val;
    },
  },
  methods: {
    onSelect(event: any) {
      this.localValue = "";
      this.$emit("onSelect", event);
    },
    async fetchEntities(): Promise<void> {
      const options = {
        limiter: {
          type: this.entityType,
          limit: 25,
        },
        filter: {},
      };

      if (this.localValue !== "") {
        options.filter = {
          q: [
            {
              key: this.searchField,
              value: this.localValue,
              operator: "~=",
              insensitify: true,
            },
          ],
        };
      }

      return this.api.getEntitiesList(options).then(({ results }: any) => {
        this.suggestions = results;
      });
    },
  },
});
