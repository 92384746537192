
import Vue from "vue";

export default Vue.extend({
  name: "FuleUploadField",
  props: {
    entity: Object,
    options: Object,
    entityType: String,
  },
  data() {
    return {
      dateValue: this.entity?.datepicker ?? null,
      minDateValue: new Date(),
    };
  },
  methods: {
    fileUploader(value: FileList) {
      this.$emit("commit", { fileUpload: [this.options.title, value] });
    },
  },
});
