export const RUSSIAN_TRANSLATIONS = {
  create: "Создать",
  update: "Изменить",
  search: "Поиск",
  noLabel: "Поле не задано",
  empty: "Пусто",
  notAllValid: "Не все поля заполнены корректно",
  defaultText: "Текст",
  bytes: {
    Bytes: "Байт",
    MB: "МБ",
    GB: "ГБ",
    TB: "ТБ",
  },
  validations: {
    requiredField: 'Поле "{attribute}" обязательно',
    schemaMinLength: 'В поле "{attribute}" должно быть минимум {min} символов',
    schemaMaxLength: 'В поле "{attribute}" должно быть максимум {max} символов',
    schemaType: 'У поля "{attribute}" должен быть тип - "{jsonType}"',
    schemaAdditionalProperties: 'Для "{attribute}" запрещены лишние поля',
    schemaPattern: `Поле "{attribute}" содержит один из недопустимых символов - &?#<>/"'=;()`,
    durationPattern: `Поле "{attribute}" содержит не соответсвует шаблону 1y 1M 1w 1d 1h 1m 1s`,
    isReferenceValid: 'Поле "{attribute}" ссылается на несуществующий объект',
    schemaMaxItems:
      'В поле "{attribute}" максимальное количество элементов - {max}',
    schemaMinItems:
      'В поле "{attribute}" минимальное количество элементов - {min}',
    schemaMinimum: 'Поле "{attribute}" не должо быть меньше {min}',
    schemaMaximum: 'Поле "{attribute}" не должо быть меньше {min}',
    schemaBetween: 'Поле "{attribute}" должно быть между {min} - {max}',
    schemaUniqueItems: 'В поле "{attribute}" должны быть уникальные элементы',
  },
  permissions: {
    all: "Все",
    tabTitle: "Права",
    byType: "По типу",
    special: "Специальные разрешения",
    default: "По типу",
    filterPlaceholder: "Поиск по разделу",
    user: "Пользователь",
    group: "Группа",
    NEGATIVE: "Запреты",
    POSITIVE: "Разрешения",
  },
  dialog: {
    iconHeader: "Выберите иконку и цвет",
  },
  tabs: {
    main: "Основные",
    service: "Сервисные",
    users: "Пользователи",
    complexObjectFields: "В объекте",
    outline: "Структура",
    license: "Лицензия",
    activation: "Активация",
    features: "Опции",
  },
  button: {
    ok: "ОК",
    yes: "Да",
    no: "Нет",
    delete: "Удалить",
    save: "Сохранить",
    saveView: "Сохранить вид",
    create: "Создать",
    cancel: "Отмена",
    change: "Изменить",
    add: "Добавить",
    deleteAll: "Очистить все",
    openAdminDialog: "Полный редактор",
  },
  message: {
    error: "Ошибка",
    success: "Выполнено",
    deleteTitle: "Удалить",
    excludeTitile: "Исключить",
    createPermission: "Создать разрешение",
    editPermission: "Изменить разрешение",
    deleteEntity: "Удалить без возможности восстановления?",
    deleteEntityFromValue: "Удалить значение из списка?",
    deleteUserFromGroup: "Исключить пользователя из группы?",
    deletePermission: "Удалить разрешение?",
    saveDefaultCameraPosition: "Сохранить позицию камеры",
    deleteAll: "Удалить все изменяемые фигуры?",
    addPolygon: "Добавить полигон",
    addPolyline: "Добавить линию",
    addSector: "Добавить вид камеры",
    addLabel: "Добавить надпись",
    addRectangle: "Добавить прямоугольник",
    drawLine: "Нарисовать линию",
    drawPolygon: "Нарисовать полигон",
    successfulCreated: "Сущность успешно создана",
    successfulUpdated: "Сущность успешно обновлена",
    noFeaturesMetadata: "Не настроена конфигурация метаданных опций!"
  },
  validation: {
    title: "Титул",
  },
  form: {
    addressLocality: "Населенный пункт",
    postalCode: "Почтовый индекс",
    postalCodeMask: "999999",
    streetAddress: "Адрес",
    format: "Формат",
    title: "Название",
    description: "Описание",
    user: "Пользователь",
    addUser: "Добавить пользователя",
    group: "Группа",
    findGroup: "Найти группу",
    scopes: "Действия",
    unknownValue: "Неизвестное значение",
    all: "Все",
    positive: "Разрешить",
    negative: "Запретить",
    findAndAdd: "Найти и добавить",
    emptyList: "Список пуст",
    emptyGroupsList: "Нет пользователей в группе",
    unnamed: "Безымянный",
    choose: "Выбрать",
    or: "Или",
    color: "Цвет",
    fileUploadLabel: "Загрузить файл",
    setValue: "Укажите значение"
  },
};
