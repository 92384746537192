
import Vue from "vue";
import AutocompleteField from "../common/AutocompleteField.vue";
import { get, set } from "lodash";
import { debounce } from "@/utils";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ReferenceField",
  components: {
    AutocompleteField,
  },
  props: {
    entity: Object as () => AdminDialogEntity,
    options: Object as () => ViewDialogTabField,
  },
  data() {
    return {
      searchField: "",
      nameFetched: false,
      name: "",
    };
  },
  methods: {
    async fetchEntityTitle(id: string, type: string) {
      try {
        const response = await this.api.getEntity({ id, type });
        if (response?.entity) {
          this.$store.commit("setValue", [
            "existingReferenceIds",
            Array.from(
              new Set([...this.existingReferenceIds, response.entity.id])
            ),
          ]);

          if (this.options.labelField) {
            return response.entity[this.options.labelField];
          }
        }

        throw new Error();
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    localValue(val, prevVal) {
      if (val !== prevVal) {
        debounce(
          () => {
            this.fetchEntityTitle(val, this.options.entityType);
          },
          200,
          "ref"
        );
      }
    },
  },
  computed: {
    ...mapState(["existingReferenceIds", "isNewEntity", "api"]),
    isEditable(): boolean {
      return (
        (this.options.editable === undefined || this.options.editable) &&
        (!this.options.disabledForEdit || this.isNewEntity) &&
        (!this.options.dependsOn?.length ||
          this.options.dependsOn?.every((key) =>
            Object.prototype.hasOwnProperty.call(this.entity, key)
          ))
      );
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string {
        return String(get(this.entity, this.options.title) || "");
      },
    },
    dependsFieldsParams(): any | undefined {
      if (this.isEditable && this.options.dependsOn?.length) {
        return this.options.dependsOn.reduce((acc, next) => {
          acc[next] = this.entity[next];
          return acc;
        }, {} as any);
      }

      return undefined;
    },
  },
  async mounted() {
    if (this.localValue) {
      this.name = await this.fetchEntityTitle(
        this.localValue,
        this.options.entityType
      );
      this.nameFetched = true;
    }
  },
});
