
import Vue from "vue";
import MetadataField from "./MetadataField.vue";
import Message from "primevue/message";
import { IEntity } from "@netvision/lib-api-repo";

export default Vue.extend({
  name: "ActivationMetadata",
  props: {
    entity: Object as () => IEntity,
    options: Object,
  },
  components: {
    MetadataField,
    Message,
  },
  watch: {
    options: {
      handler(value) {
        if (value.metadataSchema?.length) {
          const locales = this.options.metadataSchema?.map(
            ({ metadata }) => metadata
          );

          locales &&
            locales.forEach(({ entityType, localeRu }) => {
              //@ts-ignore
              const localVmMessages = this.$i18n.vm.messages;
              localVmMessages[this.$i18n.locale][entityType].attrs = {
                ...localVmMessages[this.$i18n.locale][entityType].attrs,
                ...localeRu.attrs,
              };
              localVmMessages[this.$i18n.locale][entityType].enumOptions = {
                ...localVmMessages[this.$i18n.locale][entityType].enumOptions,
                ...localeRu.enumOptions,
              };
            });
        }
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    features(): any {
      return this.options.metadataSchema?.length
        ? this.options.metadataSchema
        : [];
    },
  },
  methods: {
    commit(entity: AdminDialogEntity) {
      this.$emit("commit", entity);
    },
  },
});
