
import Vue from "vue";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import { get, set, cloneDeep } from "lodash";

type MetadataRow = {
  value: number | boolean | null;
  option?: {
    id: string;
    title: string;
    type: "number" | "boolean";
    featureMetadataKey: string;
    entityType: string;
    constraint: string;
    attr: object;
  };
};

export default Vue.extend({
  name: "MetadataField",
  components: {
    InputText,
  },
  props: {
    entity: Object,
    options: Object,
    entityType: String,
  },
  watch: {
    "entity.activation": {
      handler(value) {
        if (value && !this.isInitialised) {
          this.isInitialised = true;
          const features = get(this.entity, this.options.title);
          if (features?.length) {
            this.rows = features.map((row: any) => {
              const clonedRow = cloneDeep(row);
              const value = clonedRow.value;
              delete clonedRow.value;
              return { value, option: clonedRow };
            });
          }
        }
      },
      deep: true,
      immediate: false,
    },
    rows: {
      handler(value: MetadataRow[]) {
        this.isInitialised = true;
        const entity = cloneDeep(this.entity);
        set(
          entity,
          this.options.title,
          value.map((row) => ({ value: row.value, ...row.option }))
        );
        this.$emit("commit", entity);
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      rows: [] as MetadataRow[],
      valueComponents: new Map([
        ["number", InputNumber],
        ["boolean", InputSwitch],
      ]),
      isInitialised: false,
    };
  },
  methods: {
    addMetadataRow() {
      this.rows.push({ value: null });
    },
    removeMetadataRow(index: number) {
      this.rows = this.rows.filter((_, i: number) => i !== index);
    },
    getValueLocale(val: MetadataRow["option"]) {
      return (
        val && String(this.$t(`${this.entityType}.enumOptions.${val.title}`))
      );
    },
  },
});
