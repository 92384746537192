
import Vue from "vue";
import InputNumber from "primevue/inputnumber";
import { mapState } from "vuex";
import { get, set } from "lodash";

export default Vue.extend({
  name: "NumberField",
  components: {
    InputNumber,
  },
  props: {
    entity: Object,
    options: Object,
  },
  mounted() {
    this.fieldValue =
      Number(get({ ...this.entity }, this.options.title)) || null;
  },
  data() {
    return {
      fieldValue: null as number | null,
    };
  },
  computed: {
    ...mapState(["isNewEntity"]),
    isDisabled(): boolean {
      return (
        (this.options.disabledForEdit && !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
  },
  methods: {
    setValue(value: number | null) {
      const entity = { ...this.entity };
      set(entity, this.options.title, value ? Number(value) : value);
      this.$emit("commit", entity);
    },
  },
});
