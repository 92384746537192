// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/img/color.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/img/hue.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".moveable-line{background:transparent!important}.admin-dialog .p-datatable .p-datatable-tbody>tr>td,.admin-dialog .p-datatable .p-datatable-thead>tr>th{padding:var(--spacer-xs)}.admin-dialog .table-row{height:3rem;width:100%}.admin-dialog .table-row .p-inputtext{width:100%!important}.admin-dialog .p-tabview{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.admin-dialog .p-tree-filter-container{width:50%}.admin-dialog .p-tabview-panels{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.admin-dialog .p-inline-message,.admin-dialog .p-inline-message-text{width:100%}.admin-dialog .p-dialog-header-icons{display:none!important}.admin-dialog .p-colorpicker-preview{width:40px!important}.admin-dialog .p-selectbutton,.admin-dialog .p-tree-filter{-webkit-box-shadow:var(--shadow-raised);box-shadow:var(--shadow-raised)}.admin-dialog .p-tree-filter{background:var(--input-bg-filled);border:var(--input-border-width) solid var(--input-bg-filled)}.admin-dialog .p-colorpicker-panel .p-colorpicker-color{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0}.admin-dialog .p-colorpicker-panel .p-colorpicker-hue{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 0 0}.admin-dialog .p-dialog-content{height:100%}", ""]);
// Exports
module.exports = exports;
