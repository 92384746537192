import Vue from "vue";
import Vuex from 'vuex';
import { IRepository } from "@netvision/lib-api-repo";
import { cloneDeep } from 'lodash'

Vue.use(Vuex);

export interface State {
  isDarkTheme: boolean
  strictRegEx: RegExp
  required: Record<string, string[]>
  existingReferenceIds: (string | undefined)[]
  maxChars: number
  isNewEntity: boolean
  closeModalAfterCreate: boolean
  api: IRepository
}

const initialState: State = {
  isDarkTheme: true,
  strictRegEx: /[&?/#<>"'=;()]/,
  required: {},
  existingReferenceIds: ["", undefined],
  maxChars: 25,
  isNewEntity: true,
  closeModalAfterCreate: true,
  api: {} as IRepository,
}

export const store = new Vuex.Store({
  state: cloneDeep(initialState),
  mutations: {
    setValue(state: State, [key, value]: [keyof State, never]) {
      if (key in state)
        state[key] = value;
    },
    reset(state: State) {
      state = cloneDeep(initialState);
    },
  },
})
