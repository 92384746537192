
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

export default Vue.extend({
  name: "LicenseForm",
  props: {
    entity: Object as () => AdminDialogEntity,
    options: Object as () => ViewDialogTabField,
  },
  mounted() {
    this.$store.commit("setValue", ["closeModalAfterCreate", false]);
    const notAfterField = this.options.fields?.find(
      ({ title }) => title === "notAfter"
    );
    if (notAfterField?.minDateInDays) {
      this.licenseExpiringPeriod = notAfterField.minDateInDays;

      if (
        !this.isNewEntity &&
        this.licenseExpiringPeriod > this.licenseExpiresDaysLeft
      ) {
        this.isLicenseExpired = true;
        notAfterField.class +=
          this.licenseExpiresDaysLeft >= -1
            ? " p-label-warning"
            : " p-label-danger";
      }
    }
    if (this.options.type === "licenseActivation" && this.entity.id) {
      const currentActivation = this.entity.activations?.find(
        ({ licenseId }) => licenseId === this.entity.id
      );
      const activationExpirationField = this.options.fields?.find(
        ({ title }) => title === "activation.expiration"
      );
      if (activationExpirationField) {
        activationExpirationField.maxDate = String(this.entity.notAfter);
      }
      if (currentActivation) {
        this.api
          .getEntity({
            type: "Activation",
            id: currentActivation.id,
          })
          .then((response: any) => {
            this.commit({
              ...cloneDeep(this.entity),
              activation: {
                id: currentActivation.id,
                expiration: currentActivation.expiration,
                licenseId: currentActivation.licenseId,
                features: response?.entity?.features || [],
              },
            });
          })
          .catch((error: Error) => console.error(error));
      }
    }
  },
  inject: ["$v", "uid"],
  computed: {
    ...mapState(["api", "isNewEntity"]),
    saveLicenseButtonLabel(): TranslateResult {
      return this.licenseExpiresDaysLeft >= this.licenseExpiringPeriod ||
        this.isNewEntity
        ? this.$t("License.attrs.saveLicense")
        : this.$t("License.attrs.updateLicense");
    },
    isActionsDisabled(): boolean {
      return Boolean(this.$v.localEntity?.[`License${this.uid}`]?.$invalid);
    },
    licenseExpiresDaysLeft(): number {
      return typeof this.entity?.notAfter === "string"
        ? (new Date(this.entity?.notAfter).getTime() - this.currentDate) /
            (1000 * 3600 * 24)
        : -Infinity;
    },
  },
  watch: {
    "entity.productId": {
      handler(newVal, oldVal) {
        if (
          newVal &&
          newVal !== oldVal &&
          this.options.type === "licenseActivation"
        ) {
          this.fetchProductFeatures(newVal);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      currentDate: new Date().getTime(),
      licenseExpiringPeriod: 0,
      isLicenseExpired: false,
    };
  },
  methods: {
    commit(entity: AdminDialogEntity) {
      this.$emit("commit", entity);
    },
    fetchProductFeatures(id: string) {
      this.api
        .getEntity({
          id,
          type: "Product",
          subEntity: "featureMetadata",
        })
        .then(({ entity: ent }: any) => {
          const { items } = ent;
          const enabledMetadata = items.filter(
            ({ enabled }: { enabled?: boolean }) => enabled
          );
          const activationMetadataField = this.options.fields?.find(
            ({ type }) => type === "activationMetadata"
          );
          if (activationMetadataField && enabledMetadata.length > 0) {
            activationMetadataField.metadataSchema = enabledMetadata.map(
              (item: ActivationMetadata) => ({
                ...item,
                metadata: {
                  ...item.metadata,
                  viewMetadata: {
                    ...item.metadata.viewMetadata,
                    fields: item.metadata.viewMetadata.fields?.map((field) => ({
                      ...field,
                      featureMetadataKey: item.featureMetadataKey,
                      id: uuidv4(),
                    })),
                  },
                },
              })
            );
          }
        })
        .catch((error: Error) => console.error(error));
    },
    renewLicense() {
      if (this.licenseExpiresDaysLeft <= this.licenseExpiringPeriod) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error"),
          detail: `Дата истечения лицензии должна превышать ${this.licenseExpiringPeriod} дней`,
          life: 5000,
        });
        return false;
      }

      this.api
        .updateEntity({
          id: this.entity.id,
          type: "License",
          notAfter: this.entity.notAfter,
        })
        .then(() => {
          this.isLicenseExpired = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("success"),
            detail: "Срок действия лицензии успешно продлен",
            life: 5000,
          });
        })
        .catch((error: Error) => console.dir(error));
    },
    downloadCertificate() {
      this.api
        .download({
          id: this.entity.id,
          type: "License",
          subEntity: "certificate",
        })
        .catch((error: Error) => console.dir(error));
    },
    saveActivationData() {
      const activationPayload = {
        type: "Activation",
        licenseId: this.entity.id,
        expiration: this.entity.activation?.expiration,
        features: this.entity.activation?.features,
      };
      if (this.entity.activation?.id) {
        this.api
          .updateEntity({
            ...activationPayload,
            id: this.entity.activation.id,
          })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.success"),
              detail: this.$t("message.successfulUpdated"),
              life: 5000,
            });
          })
          .catch((error: Error) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("error"),
              detail: this.$t(error.message),
              life: 5000,
            });
          });
      } else {
        this.api
          .createEntity(activationPayload)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.success"),
              detail: this.$t("message.successfulCreated"),
              life: 5000,
            });
          })
          .catch((error: Error) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("error"),
              detail: this.$t(error.message),
              life: 5000,
            });
          });
      }
    },
    generateActivationKey() {
      console.log("Activate gen key");
    },
  },
});
