
import Vue from "vue";
import InputSwitch from "primevue/inputswitch";
import { mapState } from "vuex";
import { get, set } from "lodash";

export default Vue.extend({
  name: "ToggleField",
  components: {
    InputSwitch,
  },
  props: {
    entity: Object,
    options: Object,
  },
  mounted() {
    this.localValue =
      (this.isNewEntity && this.options.isActive) ||
      Boolean(this.entity.enabled);
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isNewEntity"]),
    disabled(): boolean {
      return (
        (this.options.disabledForEdit && !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
    localValue: {
      set(val: Boolean) {
        const entity = { ...this.entity };
        set(entity, this.options.title, Boolean(val));
        this.$emit("commit", entity);
      },
      get(): Boolean {
        return Boolean(get(this.entity, this.options.title)) || false;
      },
    },
  },
});
