
import Vue from "vue";
import { get, set } from "lodash";
import { mapState } from "vuex";

export default Vue.extend({
  name: "DatepickerField",
  props: {
    entity: Object,
    options: Object,
  },
  mounted() {
    if (this.options.minDateInDays) {
      this.minDateValue = new Date();
      this.minDateValue.setDate(
        this.minDateValue.getDate() + this.options.minDateInDays
      );
    }

    if (
      this.options.maxDateInDays &&
      this.options.maxDateInDays > this.options.minDateInDays
    ) {
      this.maxDateValue = new Date();
      this.maxDateValue.setDate(
        this.maxDateValue.getDate() + this.options.maxDateInDays
      );
    }
  },
  computed: {
    ...mapState(["isNewEntity"]),
    isDisabled(): boolean {
      if (this.isEditMode) return false;

      if (this.options.minDateInDays && this.minDateValue) {
        const fieldValueTimestamp = new Date(
          get({ ...this.entity }, this.options.title)
        ).getTime();
        if (isNaN(fieldValueTimestamp)) return false;

        const oneDay = 1000 * 60 * 60 * 24;
        const daysLeft = Math.round(
          (fieldValueTimestamp - new Date().getTime()) / oneDay
        );
        return daysLeft > this.options.minDateInDays;
      }

      return (
        !(this.options.editable === undefined || this.options.editable) ||
        (this.options.disabledForEdit && !this.isNewEntity)
      );
    },
    localValue: {
      set(val: Date) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val ? val.toISOString() : null);
        this.isEditMode = true;
        this.$emit("commit", entity);
      },
      get(): Date | null {
        const value = get({ ...this.entity }, this.options.title);
        return value ? new Date(value) : null;
      },
    },
  },
  data(): {
    minDateValue: null | Date;
    maxDateValue: null | Date;
    isEditMode: boolean;
  } {
    return {
      minDateValue: null,
      maxDateValue: null,
      isEditMode: false,
    };
  },
});
