
import Vue from "vue";
import { mapState } from "vuex";
import { get, set } from "lodash";

export default Vue.extend({
  name: "InputField",
  props: {
    entity: Object as () => AdminDialogEntity,
    options: Object as () => ViewDialogTabField,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["strictRegEx", "isNewEntity"]),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val.replace(this.strictRegEx, ""));
        this.$emit("commit", entity);
      },
      get(): String {
        return String(get(this.entity, this.options.title) || "");
      },
    },
    isDisabled(): boolean {
      return (
        (this.options.disabledForEdit && !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
  },
});
