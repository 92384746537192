var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-flex p-flex-column p-col-12 p-sm-12 p-md-6",class:[_vm.fieldClass, ...(_vm.options.class || [])],style:({ ..._vm.options.style })},[(_vm.options.showLabel === undefined || _vm.options.editable)?_c('div',{staticClass:"p-d-flex p-jc-between p-ai-center p-mb-1"},[_c('span',{class:[
        _vm.get(_vm.$v.localEntity[`${_vm.entityType}${_vm.uid}`], _vm.optionsTitle) &&
        _vm.get(_vm.$v.localEntity[`${_vm.entityType}${_vm.uid}`], _vm.optionsTitle).$invalid
          ? 'title-error'
          : '',
      ],domProps:{"textContent":_vm._s(
        `${_vm.te(
          `${_vm.entityType}.attrs.${_vm.optionsTitle.replaceAll('.', '')}`,
          _vm.optionsTitle
        )}`
      )}}),_vm._t("action")],2):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }