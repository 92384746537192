
import Vue from "vue";
import { componentMap } from "./fieldsMap";
import InputField from "./fields/InputField.vue";

export default Vue.extend({
  name: "FieldsList",
  props: {
    fields: Array as () => ViewDialogTabField[],
    entity: Object as () => AdminDialogEntity,
    entityType: String,
    context: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      InputField,
      componentMap,
    };
  },
});
