var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FieldLabel',{attrs:{"options":_vm.options,"fieldClass":'p-md-12'}},[_c('v-jsoneditor',{style:({
      width: _vm.options.width || '100%',
    }),attrs:{"options":{
      mode: 'code',
      modes: ['code', 'tree'],
      statusBar: false,
    },"plus":true,"data-cy":`${_vm.entity.type}_${_vm.options.title}`,"height":_vm.options.height || '15rem',"disabled":_vm.disabled},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }