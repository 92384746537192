
import Vue from "vue";
import { capitalize } from "@/utils";
import { get } from "lodash";

export default Vue.extend({
  name: "FieldLabel",
  props: {
    options: Object,
    fieldClass: { type: String, default: "" },
  },
  computed: {
    optionsTitle(): string {
      return this.options.alias || this.options.title;
    },
  },
  data() {
    return {};
  },
  methods: {
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : capitalize(def);
    },
    get,
  },
  inject: ["$v", "entityType", "uid"],
});
