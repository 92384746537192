
import Vue from "vue";
import AdminDialog from "./components/AdminDialog.vue";
import { mapState } from "vuex";
import { repositoryGetter, TRepoLib } from "@netvision/lib-api-repo";

export default Vue.extend({
  name: "App",
  components: {
    AdminDialog,
  },
  async mounted() {
    this.entityTypeMetadata = await this.fetchEntityMetadata();
    if (
      this.entityTypeMetadata &&
      Object.keys(this.entityTypeMetadata).length
    ) {
      this.loading = false;
    }
  },
  computed: {
    ...mapState(["api"]),
    isNeedCheckPermissions(): boolean {
      return (
        Boolean(!this.options.noTenant) &&
        Boolean(this.options.entity?.id) &&
        (this.options.hidePermissions === undefined ||
          this.options.hidePermissions)
      );
    },
  },
  data() {
    const props = this.$parent?.props;
    if (props.entity) {
      delete props.entity.$groupKey;
      delete props.entity.$index;
    } else {
      props.entity = {
        type: props.entityType,
      };
    }
    return {
      loading: true,
      options: { ...props } as AdminDialogOptions & { lib?: TRepoLib },
      required: [],
      entityTypeMetadata: {} as EntityMetadata,
      themeUnsubscribe: () => {},
    };
  },
  provide(): any {
    return {
      entityType: this.options.entityType,
      required: this.required,
      spaParent: this.$parent,
      defaultMapLocation:
        this.$parent?.props.defaultMapLocation || "53.20017221, 50.12033860",
    };
  },
  methods: {
    fetchEntityMetadata() {
      return this.api
        .getEntity({
          id: `EntityTypeMetadata:${this.options.entityType}`,
          type: "EntityTypeMetadata",
        })
        .then(({ entity: ent, metadata: mtd }: any) => {
          const entity = mtd ? mtd.entity : Array.isArray(ent) ? ent[0] : ent;
          //@ts-ignore
          this.$i18n.vm.messages[this.$i18n.locale] = {
            ...this.$i18n.messages[this.$i18n.locale],
            [this.options.entityType]: entity.localeRu,
          };
          this.required = entity?.schemaCreate?.required ?? [];
          return entity;
        })
        .catch((error: Error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("error"),
            detail: error.message,
            life: 5000,
          });
        });
    },
    updateEntity(entity: AdminDialogEntity) {
      this.options.entity = entity;
    },
  },
  beforeMount() {
    this.$store.commit("reset");
    this.$store.commit("setValue", [
      "api",
      repositoryGetter(this.options?.lib?.name),
    ]);
    if (this.$parent?.props?.entity?.id) {
      this.$store.commit("setValue", ["isNewEntity", false]);
    }
    const themeEl = document.getElementById("theme") as HTMLLinkElement & {
      setTheme: () => void;
    };
    this.$store.commit("setValue", [
      "isDarkTheme",
      themeEl.getAttribute("theme-name") === "dark",
    ]);
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === "dark");
      themeEl.addEventListener("update", listener);
      return () => themeEl.removeEventListener("update", listener);
    };
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.$store.commit("setValue", ["isDarkTheme", newValue]);
    });
  },
  beforeDestroy() {
    this.themeUnsubscribe();
  },
});
