import { IEntity } from "@netvision/lib-api-repo";
import { pickBy, mapValues, isEqual } from "lodash";
import { store } from './store'

export const capitalize = (str: string) => {
  return typeof str === "string" && str !== ""
    ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
    : "";
};
const timers = {} as any;
export const debounce = (
  callback: Function,
  timeout: number,
  key: string = "main"
) => {
  clearTimeout(timers[key]);
  timers[key] = setTimeout(callback, timeout);
};
export const createEditObject = async (
  changedEntity: IEntity,
  originalEntity: IEntity,
  closeCallback: () => void
) => {
  if (isEqual(originalEntity, changedEntity)) {
    closeCallback();
    return;
  }
  if (store.state.isNewEntity) {
    try {
      const createdEntity = await store.state.api.createEntity(changedEntity)
      if (!store.state.closeModalAfterCreate) {
        return createdEntity
      }      
      closeCallback()
      return
    } catch (error) {
      console.error(error);
      throw error
    }
  }
  try {
    await store.state.api.updateEntity({
      id: originalEntity.id,
      type: originalEntity.type,
      ...getObjectsDiff(changedEntity, originalEntity)
    });
    closeCallback();
  } catch (error) {
    console.error(error);
    throw error
  }
};
export const getObjectsDiff = (object: any, base: any) => {
  const changes = (object: any, base: any) =>
    pickBy(
      mapValues(object, (value: any, key: any) =>
        !isEqual(value, base[key]) ? value : null
      ),
      (value: any) => value !== null
    );
  return changes(object, base);
};
